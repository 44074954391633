import React from 'react';
import { observer } from 'mobx-react-lite';

import SessionDetails from '@/components/SessionDetails/SessionDetailsWrapper';
import SessionPanel from '@/components/SessionPanel/SessionPanelWrapper';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import { SessionsVCType } from './SessionsTypes';

import styles from './Sessions.module.css';

const SessionsTemplateFC: React.FC<SessionsVCType> = (props: SessionsVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, paginationStore, sessionsStore } = rootStore;
  const { isOutlookMode, isReadingPaneHidden } = appSettingsStore;
  const { isViewingDeepLink, pageSplitDirection, readingPaneKey, selectedSession } = sessionsStore;
  const { paginationType } = paginationStore;
  const {
    checkboxVisibility,
    columnEditorKey,
    columnEditorUserSettings,
    columnsList,
    commandBarItems,
    entireColumns,
    farItems,
    filterItems,
    groupByColumn,
    groupByColumns,
    hideColumnEditor,
    isColumnEditorOpen,
    isLoading,
    overflowItems,
    pageName,
    panelLoadErrorMessage,
    selection,
    selectionMode,
    selectionPreservedOnEmptyClick,
    setColumnsList,
    splitPanelConfig,
    tableConfig,
    tableItems,
    tableGroups,
  } = props;

  const isAllowResize = isOutlookMode || isViewingDeepLink; // This is off for the List with the sliding Panel mode.

  return (
    <div className={`${styles['outlook-container']} ${styles[readingPaneKey as string]}`}>
      <SplitPanel config={splitPanelConfig} index={0} isAllowResize={isAllowResize} split={pageSplitDirection}>
        {/* First child of SplitPanel, the Sessions List */}
        <div className={`${styles['primary-pane']}`}>
          <PageCommandBar farItems={farItems} items={commandBarItems} overflowItems={overflowItems}></PageCommandBar>
          <PageDivider />
          <PageFilterBar items={filterItems}></PageFilterBar>
          <TableViewViewController
            items={tableItems}
            columns={modifiedColumnConfiguration(columnsList)}
            displayColumns={tableConfig.sessionsDisplayFields}
            groups={tableGroups}
            groupByColumn={groupByColumn}
            groupByColumns={groupByColumns}
            enableToolBar={false}
            enableColumnSort={true}
            tableStyle={`${styles['sessions-table']} ${styles['full-height']}`}
            selection={selection}
            selectionMode={selectionMode}
            selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
            checkboxVisibility={checkboxVisibility}
            isCompact={true}
            isStickyHeader={true}
            isLoading={isLoading}
            paginationType={paginationType}
            pageName={pageName}
          />
        </div>
        {/* Second child of SplitPanel, the Reading Pane aka Session Details */}
        {isOutlookMode && !isReadingPaneHidden && (
          <div className={`${styles['reading-pane']}`}>
            <SessionDetails sessionId={selectedSession?.id}></SessionDetails>
          </div>
        )}
      </SplitPanel>
      {!isOutlookMode && <SessionPanel loadMessage={panelLoadErrorMessage}></SessionPanel>}
      {
        <ColumnEditorPanelTemplate
          isColumnEditorOpen={isColumnEditorOpen}
          hideColumnEditor={hideColumnEditor}
          columnsList={columnsList}
          setColumnsList={setColumnsList}
          entireColumns={entireColumns}
          columnEditorKey={columnEditorKey}
          userSettings={columnEditorUserSettings}
        ></ColumnEditorPanelTemplate>
      }
    </div>
  );
};

const SessionsTemplate = observer(SessionsTemplateFC);

export default SessionsTemplate;
