import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { t } from 'i18next';
import { inject, singleton } from 'tsyringe';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import { SplitPanelSplitType } from '@/components/SplitPanel/SplitPanelTypes';
import SplitPanelViewModel from '@/components/SplitPanel/SplitPanelViewModel';
import { CancellableStatuses, Statuses } from '@/constants/ExperimentConstants';
import {
  FilterOptions,
  KeyTextPair,
  Namespaces as NS,
  ReadingPaneCssKeys,
  SplitPanelDirectionType,
} from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { ActionTypeNull, ActionTypeVoid, NumberTypeAny } from '@/types/AppSettingsTypes';

import { LocationType, SessionFailureType, SessionStatusType, SessionType } from './SessionsTypes';

@singleton()
class SessionsStore {
  protected defaultValue = 0;
  protected groupByDefault = t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });
  protected noLabIdKey = 'no-lab-id';
  protected now = new Date();

  public labCompanyName: string;
  public pageSize: number;
  public pathSessionId: string;
  public selectedExperimentFailure: SessionFailureType | null = null;
  public selectedSession: SessionType | null = null;
  public selectedSessions: SessionType[] = null;
  public sessions: SessionType[] = [];
  public sessionGroups: any[] = [];
  public sessionsPaneSizes: number[];
  public totalSessionsCount: number;

  public endDate: Date;
  public groupByColumn: string;
  public groupByValue: string;
  public ipAddresses: string[];
  public ipAddressPairs: KeyTextPair[];
  public isDeepLinkMode: boolean;
  public isFullScreenMode: boolean;
  public isIpAddressLoading: boolean;
  public isLocationsLoading: boolean;
  public isSessionsLoading: boolean;
  public labs: LabType[];
  public lastRunTimeRange: string;
  public locations: KeyTextPair[];
  public locationValue: string;
  public searchValue: string;
  public startDate: Date;
  public statusValues: string[];

  constructor(
    @inject(LocalStorageService) protected localStorage: LocalStorageService,
    @inject(AppSettingsStore) protected appSettingsStore: AppSettingsStore,
  ) {
    makeObservable(this, {
      // Observables for Sessions configuration not persisted.
      labCompanyName: observable,
      pageSize: observable,
      pathSessionId: observable, // Used to keep track of Session to support Deep Links.
      selectedExperimentFailure: observable,
      selectedSession: observable,
      selectedSessions: observable,
      sessions: observable,
      sessionGroups: observable,
      sessionsPaneSizes: observable,
      totalSessionsCount: observable,

      // These values are persisted.
      endDate: observable,
      groupByColumn: observable,
      groupByValue: observable,
      ipAddresses: observable,
      ipAddressPairs: observable,
      isDeepLinkMode: observable,
      isFullScreenMode: observable,
      isIpAddressLoading: observable,
      isLocationsLoading: observable,
      isSessionsLoading: observable,
      labs: observable,
      lastRunTimeRange: observable,
      locations: observable,
      locationValue: observable,
      searchValue: observable,
      startDate: observable,
      statusValues: observable,

      // Actions modify the state.
      clearSelectedSession: action,
      clearSessionPaneSizes: action,
      doReset: action,
      enterFullScreenMode: action,
      exitFullScreenMode: action,
      resetLocation: action,
      setEndDate: action,
      setIpAddresses: action,
      setIpAddressPairs: action,
      setIsDeepLinkMode: action,
      setIsFullScreenMode: action,
      setIsIpAddressLoading: action,
      setIsLocationsLoading: action,
      setIsSessionsLoading: action,
      setGroupByColumn: action,
      setGroupByValue: action,
      setLabCompanyName: action,
      setLabs: action,
      setLastRunTimeRange: action,
      setLocations: action,
      setLocationValue: action,
      setPageSize: action,
      setPathSessionId: action,
      setSelectedExperimentFailure: action,
      setSessionGroups: action,
      setSessionPaneSizes: action,
      setSessions: action,
      setSearchValue: action,
      setSelectedSession: action,
      setStartDate: action,
      setStatusValues: action,
      setTotalSessionsCount: action,
      unmountSessions: action,

      // Set computed properties.
      hasAtLeastOneSession: computed,
      isSessionDataLoaded: computed,
      isSessionSelected: computed,
      isViewingAir: computed,
      isViewingDeepLink: computed,
      isViewingLab: computed,
      pageSplitDirection: computed,
      readingPaneKey: computed,
      selectedLabId: computed,
      selectedSessionId: computed,
      selectedSessionIsCancellable: computed,
      selectedSessionIsFailed: computed,
      selectedSessionStatusType: computed,
      selectedSessionTitle: computed,
    });

    this.endDate = new Date(this.localStorage.getValue(AppSettingsService.FILTER_END_DATE_KEY)) || this.now;
    this.groupByValue =
      this.localStorage.getValue(AppSettingsService.FILTER_SESSIONS_GROUPBY_KEY) ||
      t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });
    this.ipAddresses = JSON.parse(this.localStorage.getValue(AppSettingsService.FILTER_IP_ADDRESS_KEY)) || [];
    this.labs = [];
    this.lastRunTimeRange = this.localStorage.getValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY) || FilterOptions.ALL;
    this.locations = [];
    this.locationValue = this.localStorage.getValue(AppSettingsService.FILTER_LOCATION_KEY) || '';
    this.pageSize =
      this.localStorage.getValue(AppSettingsService.SESSION_PAGE_SIZE_KEY) || AppSettingsService.DEFAULT_SESSION_PAGE_SIZE;
    this.searchValue = this.localStorage.getValue(AppSettingsService.FILTER_NAME_SEARCH_KEY) || '';
    this.sessionsPaneSizes = this.localStorage.getValue(AppSettingsService.SESSION_PANE_SIZES_KEY) || this.getWindowSizes();
    this.startDate = new Date(this.localStorage.getValue(AppSettingsService.FILTER_START_DATE_KEY)) || this.now;
    this.statusValues = JSON.parse(this.localStorage.getValue(AppSettingsService.FILTER_STATUS_KEY)) || [];
  }

  // MobX Action Definitions

  public clearSelectedSession: ActionTypeVoid = () => {
    // Clear only the selected Session.
    this.setSelectedSession(null);
  };

  public clearSessionPaneSizes = (): number[] => {
    this.sessionsPaneSizes = this.getWindowSizes();

    return this.sessionsPaneSizes;
  };

  public doReset: ActionTypeVoid = () => {
    this.clearSelectedSession();
    this.clearSessionPaneSizes();
    this.setSearchValue('');
    this.setStatusValues([]);
    this.setIpAddresses([]);
    this.setGroupByValue(this.groupByDefault);
    this.setStartDate(this.now);
    this.setEndDate(this.now);
    this.setLastRunTimeRange(FilterOptions.ALL);
    this.save();
  };

  public enterFullScreenMode: ActionTypeVoid = () => {
    this.setIsFullScreenMode(true);
  };

  public exitFullScreenMode: ActionTypeVoid = () => {
    this.setIsFullScreenMode(false);
  };

  private getWindowSizes = (): number[] => {
    const windowSizes = SplitPanelViewModel.getWindowSizes();
    const currentWindowSizes = [windowSizes.width, windowSizes.height];

    return currentWindowSizes;
  };

  public resetLocation = (location: string) => {
    this.setLocationValue(location);
    this.setSearchValue('');
    this.setIpAddresses([]);
    this.setStatusValues([]);
  };

  public setEndDate = (value: Date) => {
    this.endDate = new Date(value);
    this.localStorage.setValue(AppSettingsService.FILTER_END_DATE_KEY, value);
  };

  public setGroupByColumn = (value: string): string => {
    this.groupByColumn = value;

    return this.groupByColumn;
  };

  public setGroupByValue = (value: string): string => {
    this.groupByValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_SESSIONS_GROUPBY_KEY, value);
  };

  public setIpAddresses = (values: string[]): string[] => {
    this.ipAddresses = values;

    return this.localStorage.setValue(AppSettingsService.FILTER_IP_ADDRESS_KEY, JSON.stringify(values));
  };

  public setIpAddressPairs = (values: KeyTextPair[]): KeyTextPair[] => {
    this.ipAddressPairs = values;

    return this.ipAddressPairs;
  };

  public setIsDeepLinkMode = (value: boolean): boolean => {
    this.isDeepLinkMode = value;

    return this.isDeepLinkMode;
  };

  public setIsFullScreenMode = (value: boolean): boolean => {
    this.isFullScreenMode = value;

    return this.isFullScreenMode;
  };

  public setIsIpAddressLoading = (value: boolean): boolean => {
    this.isIpAddressLoading = value;

    return this.isIpAddressLoading;
  };

  public setIsLocationsLoading = (value: boolean): boolean => {
    this.isLocationsLoading = value;

    return this.isLocationsLoading;
  };

  public setIsSessionsLoading = (value: boolean): boolean => {
    this.isSessionsLoading = value;

    return this.isSessionsLoading;
  };

  public setLabCompanyName = (name: string) => {
    this.labCompanyName = name;
  };

  public setLabs = (values: LabType[]): LabType[] => {
    this.labs = values;

    return this.labs;
  };

  public setLastRunTimeRange = (value: string): string => {
    this.lastRunTimeRange = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY, value);
  };

  public setLocations = (value: KeyTextPair[]): KeyTextPair[] => {
    this.locations = value;

    return this.locations;
  };

  public setLocationValue = (value: string): string => {
    this.locationValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_LOCATION_KEY, value);
  };

  public setPageSize: NumberTypeAny = (value: number) => {
    this.pageSize = value;

    return this.localStorage.setValue(AppSettingsService.PAGE_SIZE_KEY, value);
  };

  public setPathSessionId = (value: string): string => {
    this.pathSessionId = value?.trim();

    return this.pathSessionId;
  };

  public setSelectedExperimentFailure = (row: SessionFailureType | null): SessionFailureType | null => {
    this.selectedExperimentFailure = row;

    return this.selectedExperimentFailure;
  };

  public setSessionGroups = (value: any[]): any[] => {
    this.sessionGroups = value;

    return this.sessionGroups;
  };

  public setSessionPaneSizes = (values: number[]): number[] => {
    this.sessionsPaneSizes = values;

    return this.localStorage.setValue(AppSettingsService.SESSION_PANE_SIZES_KEY, values);
  };

  public setSessions = (rows: SessionType[] | null): SessionType[] | null => {
    this.sessions = rows;

    return this.sessions;
  };

  public setSelectedSession = (row: SessionType | null): SessionType | null => {
    this.selectedSession = row;

    return this.selectedSession;
  };

  public setSelectedSessions = (rows: SessionType[]): SessionType[] => {
    this.selectedSessions = rows || null;

    return this.selectedSessions;
  };

  public setSearchValue = (value: string): string => {
    this.searchValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_NAME_SEARCH_KEY, value);
  };

  public setStartDate = (value: Date) => {
    this.startDate = new Date(value);
    this.localStorage.setValue(AppSettingsService.FILTER_START_DATE_KEY, value);
  };

  public setStatusValues = (values: string[]) => {
    this.statusValues = values;

    return this.localStorage.setValue(AppSettingsService.FILTER_STATUS_KEY, JSON.stringify(values));
  };

  public setTotalSessionsCount = (value: number) => {
    this.totalSessionsCount = value;
  };

  public unmountSessions = () => {
    // Cleanup some Session state when we unmount.
    // We do not clear our Sessions or SessionGroups, as we want those values cached.
    // However, we do want to clear our any user made selections.
    this.clearSelectedSession();
    this.setIsDeepLinkMode(false);
    this.setPathSessionId(null);
  };

  protected save: ActionTypeNull = () => {
    this.localStorage.setValue(AppSettingsService.PAGE_SIZE_KEY, this.pageSize);
    this.localStorage.setValue(AppSettingsService.SESSION_PANE_SIZES_KEY, this.sessionsPaneSizes);

    this.localStorage.setValue(AppSettingsService.FILTER_NAME_SEARCH_KEY, this.searchValue);
    this.localStorage.setValue(AppSettingsService.FILTER_LOCATION_KEY, this.locationValue);
    this.localStorage.setValue(AppSettingsService.FILTER_STATUS_KEY, JSON.stringify(this.statusValues));
    this.localStorage.setValue(AppSettingsService.FILTER_IP_ADDRESS_KEY, JSON.stringify(this.ipAddresses));
    this.localStorage.setValue(AppSettingsService.FILTER_SESSIONS_GROUPBY_KEY, this.groupByValue);
    this.localStorage.setValue(AppSettingsService.FILTER_START_DATE_KEY, this.startDate);
    this.localStorage.setValue(AppSettingsService.FILTER_END_DATE_KEY, this.endDate);
    this.localStorage.setValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY, this.lastRunTimeRange);

    return null;
  };

  // MobX Computed Property Definitions

  public get hasAtLeastOneSession(): boolean {
    const match: boolean = this.sessions?.length > 0 || false;

    return match;
  }

  public get isSessionDataLoaded(): boolean {
    // The user has selected a Session Row and the Session Data is loaded into memory.
    const match = this.isSessionSelected && !!this.selectedSessionId;

    return match;
  }

  public get isSessionSelected(): boolean {
    // The user has selected a Session Row. This is not the same as having Session Data loaded.
    const match = !!this.selectedSession;

    return match;
  }

  public get isViewingAir(): boolean {
    // The locationValue is a string and cannot be converted to a number.
    const match = isNaN(Number(this.locationValue));

    return match;
  }

  public get isViewingDeepLink(): boolean {
    // The pathSessionId is not empty.
    const match = !!this.pathSessionId;

    return match;
  }

  public get isViewingLab(): boolean {
    // The locationValue can be converted to a number.
    const match = !isNaN(Number(this.locationValue));

    return match;
  }

  public get pageSplitDirection(): SplitPanelSplitType {
    // Determine the SplitPanel direction of the main Sessions page, based on user settings.
    const { isReadingPaneBottom, isReadingPaneRight } = this.appSettingsStore;

    if (isReadingPaneBottom) {
      return SplitPanelDirectionType.HORIZONTAL;
    } else if (isReadingPaneRight) {
      return SplitPanelDirectionType.VERTICAL;
    }

    return null;
  }

  public get readingPaneKey(): string {
    const { isReadingPaneHidden, isReadingPaneRight } = this.appSettingsStore;

    if (isReadingPaneHidden) {
      return ReadingPaneCssKeys.HIDDEN;
    }

    const match = isReadingPaneRight ? ReadingPaneCssKeys.RIGHT : ReadingPaneCssKeys.BOTTOM;

    return match;
  }

  public get selectedLabId(): string {
    const selectedSession: SessionType = this.selectedSession;
    const location: LocationType = selectedSession?.location;
    const labId: string = location?.labId?.toString() || this.noLabIdKey;

    return labId;
  }

  public get selectedSessionId(): string {
    const selectedSession: SessionType = this.selectedSession;
    const sessionId: string = selectedSession?.id || '';

    return sessionId;
  }

  public get selectedSessionIsCancellable(): boolean {
    // Returns true if the selected Session has a status that is Cancellable.
    if (!this.selectedSession) {
      return false;
    }

    const status: string = this.selectedSessionStatusType;
    const isCancellable: boolean = CancellableStatuses.includes(status);

    return isCancellable;
  }

  public get selectedSessionIsFailed(): boolean {
    const status: SessionStatusType = this.selectedSession?.status;
    const failedStatus: string = Statuses.FAILED.toLocaleLowerCase();
    const isFailed: boolean = status?.final?.toLocaleLowerCase() === failedStatus;

    return isFailed;
  }

  public get selectedSessionStatusType(): string {
    const status: string = this.selectedSession?.status?.final.toLowerCase();

    if (!status) {
      return Statuses.UNKNOWN;
    }

    // Iterate through the Statuses object and see if any value matches the status string.
    // If we have a match, return the value, otherwise return unknown.
    const matchStatus = (key: string) => Statuses[key as string].toLowerCase() === status;
    const statusKey: string = Object.keys(Statuses).find(matchStatus);
    const statusType: string = Statuses[statusKey as string] || Statuses.UNKNOWN;

    return statusType;
  }

  public get selectedSessionTitle(): string {
    const selectedSession: SessionType = this.selectedSession;
    const sessionId: string = selectedSession?.id || '';
    const sessionName: string = selectedSession?.name || '';

    if (!selectedSession && !sessionId) {
      // The selected session is not loaded yet and we do not have a valid title.
      return '';
    }

    const title: string = selectedSession ? ` ${sessionName} ${TITLE_DIVIDER} ${sessionId}` : '';

    return title;
  }
}

export default SessionsStore;
