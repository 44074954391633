import { action, computed, makeObservable, observable } from 'mobx';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { t } from 'i18next';
import { inject, singleton } from 'tsyringe';

import config from '@/components/Sessions/Sessions.config.json';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { SessionStepJsonType, SessionStepType } from '@/components/Sessions/SessionsTypes';
import { SessionLogResultsType } from '@/components/Sessions/SessionsTypes';
import { CancellableStatuses, StatusesWithLogs } from '@/constants/ExperimentConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ColumnEditorKeys, Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { TableColumnType } from '@/types/TableTypes';

@singleton()
class SessionDetailsStore {
  public static SESSION_COLUMN_DEFINITIONS = config.sessionsColumnDefinitions;
  public static SESSION_STEPS_COLUMN_DEFINITIONS = config.stepsColumnDefinitions;
  public static SESSION_EXPERIMENT_FAILURE_COLUMN_DEFINITIONS = config.sessionExperimentFailureDefinitions;

  public isCancelButtonDisabled: boolean;
  public isDownloadButtonDisabled: boolean;
  public isLogWindowItemSelected: boolean;
  public isSessionColumnEditorOpen: boolean;
  public isSessionLoading: boolean;
  public isSessionModalOpen: boolean;
  public isSessionPanelOpen: boolean;
  public isSessionStepsColumnEditorOpen: boolean;
  public isSessionStepsPopupOpen: boolean;
  public isSessionStepsWindowSelected: boolean;
  public isSessionFailureWindowSelected: boolean;
  public logPath: string;
  public sessionColumnList: TableColumnType[];
  public sessionEntireColumns: TableColumnType[];
  public sessionExperimentFailureColumns: TableColumnType[];
  public sessionLogs: SessionLogResultsType[];
  public sessionPanelMessages: SystemMessageType[];
  public sessionStep: SessionStepType;
  public sessionStepDetails: SessionStepJsonType;
  public sessionStepsColumnEditorKey: string;
  public sessionStepsColumnList: TableColumnType[];
  public sessionStepsEntireColumns: TableColumnType[];
  public sessionStepsGroupByValue: string;
  public sessionStepsGroupByColumn: string;

  constructor(
    @inject(LocalStorageService) protected localStorage: LocalStorageService,
    @inject(AppSettingsStore) protected appSettingsStore: AppSettingsStore,
    @inject(SessionsStore) protected sessionsStore: SessionsStore,
  ) {
    makeObservable(this, {
      // Observables for individual Sessions.
      sessionStep: observable,
      isCancelButtonDisabled: observable,
      isDownloadButtonDisabled: observable,
      isLogWindowItemSelected: observable,
      isSessionColumnEditorOpen: observable,
      isSessionModalOpen: observable,
      isSessionPanelOpen: observable,
      isSessionStepsColumnEditorOpen: observable,
      isSessionStepsPopupOpen: observable,
      isSessionStepsWindowSelected: observable,
      isSessionFailureWindowSelected: observable,
      logPath: observable,
      sessionLogs: observable,
      sessionColumnList: observable,
      sessionEntireColumns: observable,
      sessionExperimentFailureColumns: observable,
      sessionPanelMessages: observable,
      sessionStepDetails: observable,
      sessionStepsColumnEditorKey: observable,
      sessionStepsColumnList: observable,
      sessionStepsEntireColumns: observable,
      sessionStepsGroupByValue: observable,
      sessionStepsGroupByColumn: observable,

      // Actions modify the state.
      addSessionPanelMessage: action,
      clearSelectedSessionStep: action,
      clearSessionPanelMessages: action,
      closeSessionColumnEditor: action,
      closeSessionModal: action,
      closeSessionPanel: action,
      closeSessionStepsColumnEditor: action,
      closeSessionStepsPopup: action,
      disableCancelButton: action,
      disableDownloadButton: action,
      enableCancelButton: action,
      enableDownloadButton: action,
      openSessionColumnEditor: action,
      openSessionModal: action,
      openSessionPanel: action,
      openSessionStepsColumnEditor: action,
      openSessionStepsPopup: action,
      selectLogWindowItem: action,
      selectSessionStepsWindow: action,
      setCurrentSessionStep: action,
      setIsCancelButtonDisabled: action,
      setIsDownloadButtonDisabled: action,
      setIsLogWindowItemSelected: action,
      setIsSessionColumnEditorOpen: action,
      setIsSessionFailureWindowSelected: action,
      setIsSessionLoading: action,
      setIsSessionModalOpen: action,
      setIsSessionPanelOpen: action,
      setIsSessionStepsColumnEditorOpen: action,
      setIsSessionStepsPopupOpen: action,
      setIsSessionStepsWindowSelected: action,
      setLogPath: action,
      setSessionColumnList: action,
      setSessionEntireColumns: action,
      setSessionExperimentFailureColumns: action,
      setSessionStepsGroupByColumn: action,
      setSessionLogs: action,
      setSessionStep: action,
      setSessionStepsColumnsList: action,
      setSessionStepsEntireColumns: action,
      setSessionStepsGroupByValue: action,
      toggleSessionPanel: action,
      toggleSessionColumnEditor: action,
      unmountSessionDetails: action,

      // Set computed properties.
      canCancelSession: computed,
      canViewSessionSteps: computed,
      canViewInstanceResults: computed,
      hasPanelMessages: computed,
      isAirSession: computed,
      isLabsSession: computed,
      isSessionStepsButtonDisabled: computed,
      isSessionStepsSelected: computed,
      panelMessageCount: computed,
      selectedSessionRoutePath: computed,
      sessionHasLogs: computed,
      stepCount: computed,
    });

    // Set initial state for these observables.
    this.sessionStep = undefined;
    this.isCancelButtonDisabled = false;
    this.isDownloadButtonDisabled = false;
    this.isLogWindowItemSelected = false;
    this.isSessionColumnEditorOpen = false;
    this.isSessionLoading = false;
    this.isSessionModalOpen = false;
    this.isSessionPanelOpen = false;
    this.isSessionStepsColumnEditorOpen = false;
    this.isSessionStepsPopupOpen = false;
    this.isSessionStepsWindowSelected = false;
    this.isSessionFailureWindowSelected = false;
    this.sessionColumnList = [] as TableColumnType[];
    this.sessionEntireColumns = SessionDetailsStore.SESSION_COLUMN_DEFINITIONS;
    this.sessionExperimentFailureColumns = SessionDetailsStore.SESSION_EXPERIMENT_FAILURE_COLUMN_DEFINITIONS;
    this.sessionLogs = undefined;
    this.sessionStepsColumnEditorKey = ColumnEditorKeys.SESSION_STEP;
    this.sessionStepsColumnList = [] as TableColumnType[];
    this.sessionStepDetails = null;
    this.sessionStepsEntireColumns = SessionDetailsStore.SESSION_STEPS_COLUMN_DEFINITIONS;
    this.sessionStepsGroupByValue =
      this.localStorage.getValue(AppSettingsService.FILTER_STEPS_GROUPBY_KEY) || t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });
    this.sessionStepsGroupByColumn = '';
    this.sessionPanelMessages = [] as SystemMessageType[];
  }

  public addSessionPanelMessage = (message: string | SystemMessageType) => {
    if (typeof message === 'string') {
      const systemMessage: SystemMessageType = {
        message: message,
        type: FluentMessageBarType.success,
        mode: MessageBarMode.normal,
        groupId: 'session-panel-message',
      };

      this.sessionPanelMessages.push(systemMessage as SystemMessageType);
    } else if (message) {
      this.sessionPanelMessages.push(message as SystemMessageType);
    }
  };

  public clearSelectedSessionStep = () => {
    this.setSessionStep(null);
    this.setCurrentSessionStep(null);
    this.setIsSessionStepsPopupOpen(false);
  };

  public clearSessionPanelMessages = () => {
    this.sessionPanelMessages = [] as SystemMessageType[];
  };

  public closeSessionColumnEditor = () => {
    this.setIsSessionColumnEditorOpen(false);
  };

  public closeSessionModal = () => {
    this.setIsSessionModalOpen(false);
  };

  public closeSessionPanel = () => {
    this.setIsSessionPanelOpen(false);
    this.closeSessionStepsColumnEditor(); // Since the Panel is closed, close any open Column Editors in the Panel.
    this.clearSessionPanelMessages();
    this.enableCancelButton();
    this.enableDownloadButton();
  };

  public closeSessionStepsColumnEditor = () => {
    this.setIsSessionStepsColumnEditorOpen(false);
  };

  public closeSessionStepsPopup = () => {
    this.setIsSessionStepsPopupOpen(false);
  };

  public disableCancelButton = () => {
    this.setIsCancelButtonDisabled(true);
  };

  public disableDownloadButton = () => {
    this.setIsDownloadButtonDisabled(true);
  };

  public enableCancelButton = () => {
    this.setIsCancelButtonDisabled(false);
  };

  public enableDownloadButton = () => {
    this.setIsDownloadButtonDisabled(false);
  };

  public openSessionColumnEditor = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionColumnEditorOpen(true);
    this.closeSessionPanel(); // Since the user wants to Edit Columns for the parent page, close the Panel.

    closeSettings();
  };

  public openSessionModal = () => {
    this.closeSessionPanel();
    this.setIsSessionModalOpen(true);
  };

  public openSessionPanel = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionPanelOpen(true);
    this.closeSessionColumnEditor(); // Since the Panel is open, close any open Column Editors in the main page.

    closeSettings();
  };

  public openSessionStepsColumnEditor = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionStepsColumnEditorOpen(true);

    closeSettings();
  };

  public openSessionStepsPopup = () => {
    if (this.canViewSessionSteps) {
      this.setIsSessionStepsPopupOpen(true);
    }
  };

  public selectLogWindowItem = () => {
    this.setIsLogWindowItemSelected(true);
    this.setIsSessionStepsWindowSelected(false);
    this.setIsSessionFailureWindowSelected(false);
  };

  public selectSessionStepsWindow = () => {
    this.setIsLogWindowItemSelected(false);
    this.setIsSessionStepsWindowSelected(true);
    this.setIsSessionFailureWindowSelected(false);
  };

  public selectFailureWindowItem = () => {
    this.setIsSessionFailureWindowSelected(true);
    this.setIsLogWindowItemSelected(false);
    this.setIsSessionStepsWindowSelected(false);
  };

  public setCurrentSessionStep = (value: SessionStepJsonType) => {
    this.sessionStepDetails = value;
  };

  public setIsCancelButtonDisabled = (value: boolean) => {
    this.isCancelButtonDisabled = value;
  };

  public setIsDownloadButtonDisabled = (value: boolean) => {
    this.isDownloadButtonDisabled = value;
  };

  public setIsLogWindowItemSelected = (value: boolean) => {
    this.isLogWindowItemSelected = value;
  };

  public setIsSessionStepsPopupOpen = (value: boolean) => {
    this.isSessionStepsPopupOpen = value;
  };

  public setIsSessionLoading = (value: boolean) => {
    this.isSessionLoading = value;
  };

  public setIsSessionModalOpen = (value: boolean) => {
    this.isSessionModalOpen = value;
  };

  public setIsSessionPanelOpen = (value: boolean) => {
    this.isSessionPanelOpen = value;
  };

  public setIsSessionColumnEditorOpen = (value: boolean) => {
    this.isSessionColumnEditorOpen = value;
  };

  public setIsSessionStepsColumnEditorOpen = (value: boolean) => {
    this.isSessionStepsColumnEditorOpen = value;
  };

  public setIsSessionStepsWindowSelected = (value: boolean) => {
    this.isSessionStepsWindowSelected = value;
  };

  public setIsSessionFailureWindowSelected = (value: boolean) => {
    this.isSessionFailureWindowSelected = value;
  };

  public setLogPath = (value: string) => {
    this.logPath = value;
  };

  public setSessionColumnList = (value: TableColumnType[]) => {
    this.sessionColumnList = value;
  };

  public setSessionEntireColumns = (value: TableColumnType[]) => {
    this.sessionEntireColumns = value;
  };

  public setSessionExperimentFailureColumns = (value: TableColumnType[]) => {
    this.sessionExperimentFailureColumns = value;
  };

  public setSessionLogs = (value: SessionLogResultsType[]) => {
    this.sessionLogs = value;
  };

  public setSessionStep = (value: SessionStepType) => {
    this.sessionStep = value;
  };

  public setSessionStepsGroupByColumn = (value: string) => {
    this.sessionStepsGroupByColumn = value;
  };

  public setSessionStepsColumnsList = (value: TableColumnType[]) => {
    this.sessionStepsColumnList = value;
  };

  public setSessionStepsEntireColumns = (value: TableColumnType[]) => {
    this.sessionStepsEntireColumns = value;
  };

  public setSessionStepsGroupByValue = (value: string): string => {
    this.sessionStepsGroupByValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_STEPS_GROUPBY_KEY, value);
  };

  public toggleSessionPanel = () => {
    this.isSessionPanelOpen ? this.closeSessionPanel() : this.openSessionPanel();
  };

  public toggleSessionColumnEditor = () => {
    this.isSessionColumnEditorOpen ? this.closeSessionColumnEditor() : this.openSessionColumnEditor();
  };

  public unmountSessionDetails = () => {
    // Cleanup Session Details when we unmount.
    this.closeSessionModal();
    this.closeSessionPanel();
    this.closeSessionStepsPopup();
    this.closeSessionStepsColumnEditor();
    this.clearSelectedSessionStep();
    this.clearSessionPanelMessages();
  };

  public get canCancelSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    if (!selectedSession || this.isAirSession) {
      return false;
    }

    const status = isSessionSelected && selectedSession.status?.data['Succeeded']?.toString().toLowerCase();
    const canCancel = CancellableStatuses.includes(status);

    return canCancel;
  }

  public get canViewInstanceResults(): boolean {
    // If the Experiment Instance has logs, and the status is failed or succeeded,
    // the results can be viewed.
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    if (!isSessionSelected) {
      return false;
    }

    const hasLogs = !!this.sessionHasLogs;
    const status = selectedSession.status?.data['Succeeded']?.toString().toLowerCase();
    const validStatus = StatusesWithLogs.includes(status);
    const canView = hasLogs && validStatus;

    return canView;
  }

  public get canViewSessionSteps(): boolean {
    // If a step is selected, and the status is failed or succeeded, it can also be viewed.
    const canView = this.isSessionStepsSelected;

    return canView;
  }
  public get hasPanelMessages(): boolean {
    const hasMessages = this.sessionPanelMessages.length > 0;

    return hasMessages;
  }

  public get isAirSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    return (isSessionSelected && selectedSession.location?.location === SystemType.AIR) || false;
  }

  public get isLabsSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    return (isSessionSelected && selectedSession.location?.location === SystemType.LABS) || false;
  }

  public get isSessionStepsButtonDisabled(): boolean {
    // Disabled if a step cannot be viewed or the popup is already open.
    const disabled = !this.canViewSessionSteps || this.isSessionStepsPopupOpen;

    return disabled;
  }

  public get isSessionStepsSelected(): boolean {
    // If the step details exists, it has been selected.
    const selected = !!this.sessionStep;

    return selected;
  }

  public get selectedSessionRoutePath(): string {
    const sessionsStore = this.sessionsStore;
    const { selectedSession } = sessionsStore;

    if (!selectedSession) {
      // A session must be selected to build the path.
      return '';
    }

    const experimentPathPrefix: string = Navigation.GANYMEDE.SESSIONS;
    const experimentId: string = selectedSession.id;

    return `${experimentPathPrefix}/${experimentId}`;
  }

  public get sessionHasLogs(): boolean {
    // If the logs object is not empty, we have some logs.
    const hasLogs = this.sessionLogs?.length > 0 || false;

    return hasLogs;
  }

  public get stepCount(): number {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;
    const count = (isSessionSelected && selectedSession.steps?.length) || 0;

    return count;
  }

  public get panelMessageCount(): number {
    const count = this.sessionPanelMessages.length;

    return count;
  }
}

export default SessionDetailsStore;
