import React, { FC, ReactElement, useContext } from 'react';
import ReactJson from 'react-json-view';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@fluentui/react';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import config from '@/components/Sessions/Sessions.config.json';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS, SplitPanelDirectionType } from '@/constants/SystemConstants';
import { LogsView, Session } from '@/constants/TranslationConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import { SessionDetailsStepsVCType } from '../SessionDetailsTypes';

import '@/styles/SplitPanel.css';
import styles from '../SessionDetails.module.css';

const SessionDetailsStepsTemplateFC: FC<SessionDetailsStepsVCType> = (props: SessionDetailsStepsVCType) => {
  const rootStore: RootStore = useContext(RootStoreContext);
  const { appSettingsStore, sessionDetailsStore, sessionsStore } = rootStore;

  const { isOutlookMode } = appSettingsStore;
  const {
    isSessionSelected,
    isSessionDataLoaded,
    isViewingDeepLink,
    labCompanyName,
    selectedExperimentFailure,
    selectedSessionIsFailed,
  } = sessionsStore;
  const {
    closeSessionStepsColumnEditor,
    isLogWindowItemSelected,
    isSessionFailureWindowSelected,
    isSessionStepsColumnEditorOpen,
    isSessionStepsSelected,
    isSessionStepsWindowSelected,
    logPath,
    selectLogWindowItem,
    selectSessionStepsWindow,
    selectFailureWindowItem,
    sessionExperimentFailureColumns,
    sessionStep,
    sessionStepsColumnList,
    sessionStepsColumnEditorKey,
    sessionStepsEntireColumns,
    setSessionStepsColumnsList,
  } = sessionDetailsStore;

  const {
    agentFilterItems,
    checkboxVisibility,
    columnEditorUserSettings,
    detailsSplitDirection,
    farItems,
    filteredStep,
    groupByColumn,
    groupByColumns,
    selection,
    splitPanelConfig,
    stepDisplayItems,
    tableGroups,
  } = props;

  const isLoadingSteps = !stepDisplayItems;

  let stepsTabContent: ReactElement;

  if (isLoadingSteps) {
    stepsTabContent = (
      <div className={styles['flex-column-container']}>
        <LoadingSpinner />
      </div>
    );
  } else {
    // NOTE: Eventually move these to the ViewController.
    const filteredSteps = filteredStep(stepDisplayItems);
    const selectedStepJson: object = isSessionSelected ? (sessionStep?.stepJSON as object) : {};
    const selectedFailureAnalysis: any = selectedExperimentFailure;
    const showFailureAnalysisTable: boolean = selectedSessionIsFailed && selectedFailureAnalysis !== null;
    const stepName: string = sessionStep?.name;

    stepsTabContent = (
      <>
        <PageFilterBar items={agentFilterItems} farItems={farItems} />
        <div className={`${styles['flex-column-container']} ${styles['filtered-steps-container']}`}>
          <SplitPanel
            config={splitPanelConfig}
            index={0}
            isAllowResize={isOutlookMode || isViewingDeepLink}
            split={detailsSplitDirection}
          >
            <div className={`${styles['details-primary-pane']}`}>
              <TableViewViewController
                checkboxVisibility={checkboxVisibility}
                columns={modifiedColumnConfiguration(sessionStepsColumnList)}
                displayColumns={config.stepsDisplayFields}
                enableToolBar={false}
                tableStyle={styles['session-steps-table']}
                selection={selection}
                selectionMode={SelectionMode.single}
                groups={tableGroups}
                groupByColumn={groupByColumn}
                groupByColumns={groupByColumns}
                isCompact={true}
                isStickyHeader={true}
                items={filteredSteps}
              />
            </div>
            <div className={`${styles['details-secondary-pane']}`}>
              {/* In Outlook Mode, we will remove the Title */}
              {!isOutlookMode && <div className={styles['step-details-title']}>{t('step-details', { ns: NS.EXPERIMENTS })}</div>}
              <div className={styles['step-details-container']}>
                <SplitPanel
                  config={splitPanelConfig}
                  index={1}
                  isAllowResize={isOutlookMode || isViewingDeepLink}
                  split={SplitPanelDirectionType.VERTICAL}
                >
                  <div className={`${styles['flex-column-container']} ${styles['step-details-list-pane']}`}>
                    <div
                      className={`${styles['title']}${isSessionStepsWindowSelected ? ` ${styles['hot']}` : ''}`}
                      onClick={() => selectSessionStepsWindow()}
                    >
                      <IconButton
                        aria-label={t('steps-json', { ns: NS.EXPERIMENTS })}
                        iconProps={{ iconName: SystemIcons.STEPS_JSON }}
                      />
                      {t('steps-json', { ns: NS.EXPERIMENTS })}
                    </div>
                    <div className={styles['row-divider']}></div>
                    <div
                      className={`${styles['title']}${isLogWindowItemSelected ? ` ${styles['hot']}` : ''}`}
                      onClick={() => selectLogWindowItem()}
                    >
                      <IconButton aria-label={t(LogsView.LOGS, { ns: NS.LOGS_VIEW })} iconProps={{ iconName: SystemIcons.LOGS }} />
                      {t(LogsView.LOGS, { ns: NS.LOGS_VIEW })}
                    </div>

                    {isLogWindowItemSelected && sessionStep?.experimentId && (
                      <>
                        <div className={styles['row-divider']}></div>
                        <RawLogsTreeView
                          executedBy={LogsView.SESSION_DETAIS_STEPS_TEMPLATE}
                          id={sessionStep?.experimentId}
                          companyName={labCompanyName}
                          path={logPath}
                          showLogText={false}
                          isTitleHighlighted={isLogWindowItemSelected}
                        />
                      </>
                    )}
                    <div className={styles['row-divider']}></div>

                    {selectedSessionIsFailed && (
                      <div
                        className={`${styles['title']}${isSessionFailureWindowSelected ? ` ${styles['hot']}` : ''}`}
                        onClick={() => selectFailureWindowItem()}
                      >
                        <IconButton
                          aria-label={t(Session.FAILURE_ANALYSIS, { ns: NS.EXPERIMENTS })}
                          iconProps={{ iconName: SystemIcons.FAILURE_ANALYSIS }}
                        />
                        {t(Session.FAILURE_ANALYSIS, { ns: NS.EXPERIMENTS })}
                      </div>
                    )}
                  </div>
                  <div className={`${styles['step-details-content-pane']}`}>
                    <div className={`${styles['flex-column-container']} ${styles['breakable-block']}`}>
                      {isSessionStepsWindowSelected && (
                        <>
                          {!isSessionStepsSelected && (
                            <div className={styles['centered-message']}>{t('select-step-for-json', { ns: NS.EXPERIMENTS })}</div>
                          )}
                          {isSessionDataLoaded && isSessionStepsSelected && (
                            <>
                              <div className={`${styles['title']}`}>{stepName}</div>
                              <div className={`${styles['breakable-block']} ${styles['scrollable-block']}`}>
                                {<ReactJson src={selectedStepJson} />}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {isLogWindowItemSelected && (
                        <>
                          {sessionStep && <FilePreview companyName={labCompanyName} />}
                          {!sessionStep && (
                            <div className={styles['centered-message']}>{t('select-step-for-logs', { ns: NS.EXPERIMENTS })}</div>
                          )}
                        </>
                      )}
                      {isSessionFailureWindowSelected && (
                        <>
                          {showFailureAnalysisTable && (
                            <TableViewViewController
                              items={selectedFailureAnalysis}
                              columns={modifiedColumnConfiguration(sessionExperimentFailureColumns)}
                              displayColumns={config.sessionExperimentFailureDisplayFields}
                              enableToolBar={false}
                              enableColumnSort={true}
                              tableStyle={styles['session-steps-table']}
                              checkboxVisibility={checkboxVisibility}
                              isCompact={true}
                              isStickyHeader={true}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </SplitPanel>
              </div>
            </div>
          </SplitPanel>
          <ColumnEditorPanelTemplate
            columnEditorKey={sessionStepsColumnEditorKey}
            columnsList={sessionStepsColumnList}
            entireColumns={sessionStepsEntireColumns}
            hideColumnEditor={closeSessionStepsColumnEditor}
            isColumnEditorOpen={isSessionStepsColumnEditorOpen}
            setColumnsList={setSessionStepsColumnsList}
            userSettings={columnEditorUserSettings}
          />
        </div>
      </>
    );
  }

  return <>{isSessionDataLoaded && stepsTabContent}</>;
};

const SessionDetailsStepsTemplate = observer(SessionDetailsStepsTemplateFC);

export default SessionDetailsStepsTemplate;
