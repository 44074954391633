import React from 'react';
import { ThemeKeys } from 'react-json-view';
import { Link } from 'react-router-dom';
import { vs, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { observer } from 'mobx-react-lite';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  ChoiceGroup,
  CommandBarButton,
  DatePicker,
  DefaultButton,
  Icon,
  IconButton,
  MessageBarType as FluentMessageBarType,
  SearchBox,
} from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/Selection';
import { Divider } from '@fluentui/react-components';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';
import ls from 'local-storage';

import { loginRequest } from '@/components/_labs/SignIn/resultAuthConfig';
import { SessionStepType, SessionType } from '@/components/Sessions/SessionsTypes';
import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import { TimeInterval, TimeRangeOptions } from '@/constants/DateFormatConstants';
import { TabMemoryKeys } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import {
  FilterOptions,
  Namespaces as NS,
  ReadingPaneStateKeys,
  SplitPanelDirectionType,
  SuccessGroupIds,
} from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { ColumnEditorUserSettingsType } from '@/partials/ColumnEditorPanel/ColumnEditorPanelTypes';
import { onDismissType } from '@/partials/MessageBar/MessageBarTypes';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { useCancellationToken } from '@/services/_labs/screen-service';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { formatDate } from '@/utils/Dates';
import { getResultExplorerAuthData } from '@/utils/Env';
import { createGroupByNoneItem, setTableDataGroupBy } from '@/utils/GroupBy';
import { processColumns } from '@/utils/Tables';

import SessionDetailsStore from './SessionDetailsStore';
import SessionDetailsTemplate from './SessionDetailsTemplate';
import { SessionDetailsVMType } from './SessionDetailsTypes';

import styles from './SessionDetails.module.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface SessionDetailsVCProps {
  viewModel: SessionDetailsVMType;
}

const SessionDetailsViewControllerFC: React.FC<SessionDetailsVCProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, editColumnsStore, sessionsStore, sessionDetailsStore, userInfoStore, userSettingsStore } = rootStore;
  const {
    isDarkMode,
    isOutlookMode,
    isReadingPaneBottom,
    isReadingPaneRight,
    setPreviewPaneBottom,
    setPreviewPaneRight,
    setTabMemory,
  } = appSettingsStore;
  const {
    clearSelectedSession,
    selectedSession,
    isSessionSelected,
    setSelectedSession,
    selectedSessionIsCancellable,
    selectedSessionTitle,
  } = sessionsStore;
  const {
    canCancelSession,
    clearSelectedSessionStep,
    clearSessionPanelMessages,
    isCancelButtonDisabled,
    isDownloadButtonDisabled,
    openSessionStepsColumnEditor,
    selectedSessionRoutePath,
    selectSessionStepsWindow,
    sessionStepsColumnEditorKey,
    sessionStepsEntireColumns,
    sessionStepsGroupByValue,
    sessionStepsGroupByColumn,
    setSessionStep,
    setSessionStepsColumnsList,
    setSessionStepsEntireColumns,
    setSessionStepsGroupByColumn,
    setSessionStepsGroupByValue,
  } = sessionDetailsStore;
  const { timeZone } = userSettingsStore;
  const { companyName } = userInfoStore;

  const {
    filterStepData,
    getExperimentFailures,
    getLogPathFromExperimentInstance,
    logDownloader,
    loadSession,
    loadSessionSteps,
    sessionId,
  } = viewModel;
  const cancellationToken = useCancellationToken();
  const stepsColumnDefinitions = SessionDetailsStore.SESSION_STEPS_COLUMN_DEFINITIONS;
  const groupByNoneKey = t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });

  const [tableGroups, setTableGroups] = React.useState<any[] | null>(null);

  const [content, setContent] = React.useState<React.ReactElement>(<></>);
  const [chart, setChart] = React.useState<React.ReactElement>();
  const tooltipId = useId('tooltip');
  const [stepDisplayItems, setStepDisplayItems] = React.useState<SessionStepType[]>(selectedSession?.steps);
  const [rawJsonStyle, setRawJsonStyle] = React.useState<any>(vs);
  const [compiledJsonStyle, setCompiledJsonStyle] = React.useState<ThemeKeys>('bright:inverted');

  const [experimentPanelAgentStepSearchValue, setExperimentPanelAgentStepSearchValue] = React.useState<string>('');
  const [experimentPanelAgentStepStatusValues, setExperimentPanelAgentStepStatusValues] = React.useState<string[]>([]);
  const [experimentPanelAgentStepTypeValues, setExperimentPanelAgentStepTypeValues] = React.useState<string[]>([]);
  const [experimentPanelAgentStepStartDate, setExperimentPanelAgentStepStartDate] = React.useState<Date>(new Date());
  const [experimentPanelAgentStepEndDate, setExperimentPanelAgentStepEndDate] = React.useState<Date>(new Date());
  const [experimentPanelAgentStepTimeRange, setExperimentPanelAgentStepTimeRange] = React.useState<string>(FilterOptions.ALL);

  const stepsStatusList = selectedSession?.stepsStatusList;
  const stepsTypeList = selectedSession?.stepsTypeList;

  const handleClearAgentStepFilters = () => {
    setExperimentPanelAgentStepSearchValue('');
    setExperimentPanelAgentStepStatusValues([]);
    setExperimentPanelAgentStepTypeValues([]);
    setExperimentPanelAgentStepStartDate(new Date());
    setExperimentPanelAgentStepEndDate(new Date());
    setExperimentPanelAgentStepTimeRange(FilterOptions.ALL);
  };

  const stepChanges: string | string[] | Date =
    experimentPanelAgentStepSearchValue +
    experimentPanelAgentStepStatusValues +
    experimentPanelAgentStepTypeValues +
    experimentPanelAgentStepTimeRange +
    experimentPanelAgentStepStartDate +
    experimentPanelAgentStepEndDate;

  const fetchSession = React.useCallback(async (sessionId: string) => {
    // Load the session details for the selected sessionId.

    if (!sessionId) {
      // We do not have a session to load.
      console.error('[SessionDetailsViewControllerFC:fetchSession] No SessionId provided to fetch Session Details.');

      return;
    }

    await loadSession(sessionId, cancellationToken);

    fetchSessionSteps(sessionId);
  }, []);

  const fetchSessionSteps = React.useCallback(async (sessionId: string) => {
    // Load the session steps for the selected session.

    if (!sessionId) {
      // We do not have a session to load.
      console.error('[SessionDetailsViewControllerFC:fetchSessionSteps] No SessionId provided to fetch Step Details.');

      return;
    }

    await loadSessionSteps(sessionId, cancellationToken);
  }, []);

  const buildStepsTab = (steps: SessionStepType[]) => {
    if (steps) {
      setChart(null);
      setStepDisplayItems(steps);

      if (sessionStepsGroupByColumn && sessionStepsGroupByColumn !== '') {
        setGroupByData(steps, sessionStepsGroupByColumn);
      } else {
        setGroupByData(steps, sessionStepsGroupByValue);
      }
    } else {
      setChart(null);
    }
  };

  React.useEffect(() => {
    if (sessionId) {
      fetchSession(sessionId);
    }
  }, [sessionId]);

  // Configure our Columns for the Step Details Table Column Editor.
  React.useEffect(() => {
    const { getEditorColumns } = editColumnsStore;
    const storedColumns = getEditorColumns(sessionStepsColumnEditorKey);
    const { userColumns, allColumns } = processColumns(storedColumns, sessionStepsEntireColumns);

    setSessionStepsColumnsList(userColumns);
    setSessionStepsEntireColumns(allColumns);
    selectSessionStepsWindow();
  }, []);

  React.useEffect(() => {
    handleClearAgentStepFilters();
    clearSelectedSessionStep();
    setSelectedSession(selectedSession);
  }, [selectedSession]);

  React.useEffect(() => {
    const steps: SessionStepType[] = selectedSession?.steps;

    // If we have a change in Steps data, rebuild the Steps Tab.
    if (steps) {
      buildStepsTab(steps);
    }
  }, [selectedSession?.steps]);

  React.useEffect(() => {
    setRawJsonStyle(isDarkMode ? vs2015 : vs);
    setCompiledJsonStyle(isDarkMode ? 'bright' : 'bright:inverted');
  }, [isDarkMode]);

  const cancelConfirmation = t('cancel-session-confirmation', { ns: NS.EXPERIMENTS });

  const onSelectRangeAgentSteps = (key: string) => {
    const now = new Date();
    const startDate = new Date();

    setExperimentPanelAgentStepTimeRange(key);

    if (key !== FilterOptions.ALL && key !== FilterOptions.CUSTOM) {
      startDate.setHours(now.getHours() - Number(key));
      setExperimentPanelAgentStepStartDate(startDate);
      setExperimentPanelAgentStepEndDate(now);
    }
  };

  const filteredStep = (steps: SessionStepType[]) => {
    if (steps?.length > 0) {
      const filteredResult = filterStepData(
        steps,
        experimentPanelAgentStepSearchValue,
        experimentPanelAgentStepStatusValues,
        experimentPanelAgentStepTypeValues,
        experimentPanelAgentStepStartDate,
        experimentPanelAgentStepEndDate,
        experimentPanelAgentStepTimeRange,
      );

      return filteredResult;
    }

    return [];
  };

  const setGroupByData = (tableData: any[], groupByField?: string) => {
    const groupByColumn = groupByField ?? sessionStepsGroupByValue;
    const { returnData, groups } = setTableDataGroupBy(tableData, groupByColumn, stepsColumnDefinitions);

    setStepDisplayItems(returnData);
    setTableGroups(groups);
    setSessionStepsGroupByColumn(groupByColumn);
  };

  const groupByProps: ICommandBarItemProps[] = stepsColumnDefinitions
    .filter((item) => item.isGroupBy)
    .map((item) => {
      const groupByName = t(item.name, { ns: NS.TABLE });

      return {
        key: item.key,
        text: groupByName,
        onClick: () => handleGroupBySelected(item.key),
      };
    });

  const handleGroupBySelected = (column: string) => {
    setSessionStepsGroupByValue(column);
    setGroupByData(stepDisplayItems, column);
  };

  const groupByNoneItem = createGroupByNoneItem(handleGroupBySelected);

  const handlePaginationChange = () => {
    filteredStep(stepDisplayItems);
  };

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const data = getResultExplorerAuthData();
  const [isToken, setIsTokenGenerated] = React.useState<boolean>(false);
  const nineMinsToMillisec = TimeInterval.NINE_MINS;

  const setTokenData = React.useCallback(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        scopes: ['openid', data.scope],
        account: accounts[0],
      });
      setIsTokenGenerated(true);
      (ls as any).set('resultsToken', response.accessToken);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          await instance.acquireTokenRedirect({ scopes: ['openid', data.scope] });
        } catch (redirectError) {
          console.error('[SessionDetailsViewControllerFC:setTokenData] Error acquiring token via redirect:', redirectError);
        }
      } else {
        console.error('[SessionDetailsViewControllerFC:setTokenData] Error acquiring token silently:', error);
      }
    }
  }, [accounts, data.scope, instance]);

  const acquireTokenSilent = React.useCallback(() => {
    instance.acquireTokenSilent(loginRequest).catch((e) => {
      console.error('Error from Results token creation ', e);
    });
  }, [instance]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setTokenData();
    }
  }, [isAuthenticated, setTokenData]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        setTokenData();
      } else {
        acquireTokenSilent();
      }
    }, nineMinsToMillisec);

    return () => {
      clearInterval(intervalId);
    };
  }, [isAuthenticated, setTokenData, acquireTokenSilent]);

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      // clearSelectedSessionStep();

      return selectStep(selection);
    },
  });

  const selectStep = (item: Selection) => {
    const { isLogWindowItemSelected } = sessionDetailsStore;
    const selectedData: IObjectWithKey[] = item.getSelection();
    const selection: SessionStepType | null = selectedData[0] as SessionStepType | null;

    if (selection) {
      if (!isLogWindowItemSelected) {
        selectSessionStepsWindow();
      }

      setSessionStep(selection as SessionStepType);
      getLogPathFromExperimentInstance(cancellationToken);
      getExperimentFailures(cancellationToken);
    }
  };

  const cancelOnClick = () => {
    // Cancel session.
    // TODO: Implement cancel session via API (28393226).
    const { addSessionPanelMessage, enableCancelButton, disableCancelButton } = sessionDetailsStore;
    const cancelConfirmation = t('cancel-session-confirmation', { ns: NS.EXPERIMENTS });

    const cancelMessage: SystemMessageType = {
      message: t('cancel-not-implemented', { ns: NS.EXPERIMENTS }),
      namespace: NS.EXPERIMENTS,
      type: FluentMessageBarType.info,
      groupId: SuccessGroupIds.SESSION_VIEW,
    };

    const simulateCancelOperation = () => {
      enableCancelButton();
    };

    if (confirm(cancelConfirmation)) {
      disableCancelButton();
      setTimeout(simulateCancelOperation, TimeInterval.THREE_SECONDS);
      addSessionPanelMessage(cancelMessage);
    }
  };

  const menuPropsAgentSteps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: (item, dismissMenu) => (
          <ChoiceGroup
            className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
            styles={filterBar.choiceGroup}
            selectedKey={experimentPanelAgentStepTimeRange}
            options={TimeRangeOptions}
            onChange={(ev: any, option: any) => {
              onSelectRangeAgentSteps(option.key);
            }}
          />
        ),
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            <DatePicker
              label={t('start-date', { ns: NS.TABLE })}
              disabled={experimentPanelAgentStepTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setExperimentPanelAgentStepStartDate}
              value={experimentPanelAgentStepStartDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(experimentPanelAgentStepStartDate.toString(), timeZone)}
            />
            <DatePicker
              label={t('end-date', { ns: NS.TABLE })}
              disabled={experimentPanelAgentStepTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setExperimentPanelAgentStepEndDate}
              value={experimentPanelAgentStepEndDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(experimentPanelAgentStepEndDate.toString(), timeZone)}
            />
          </div>
        ),
      },
    ],
  };

  const generalCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'download-all-logs',
      text: t('download-all-logs', { ns: NS.EXPERIMENTS }),
      title: t('download-all-logs', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        // Download all logs.
        // TODO: Implement download all logs via API (28288607).
        const { disableDownloadButton } = sessionDetailsStore;

        if (isSessionSelected) {
          // NOTE: This is a temporary solution to pass the id to the logDownloader function.
          const id = '5d413ec8-db36-4a92-82fd-7dbeb7c32112'; // selectedSession.id;

          disableDownloadButton();
          logDownloader(id, companyName);
        }
      },
      disabled: isDownloadButtonDisabled,
    },
    {
      key: 'cancel-session',
      text: t('cancel-session', { ns: NS.EXPERIMENTS }),
      title: t('cancel-session', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.CANCEL },
      onClick: cancelOnClick,
      disabled: !selectedSessionIsCancellable || isCancelButtonDisabled,
    },
  ];

  const farItems: ICommandBarItemProps[] = [];

  const agentFilterItems: IOverflowSetItemProps[] = [
    {
      key: 'panel-search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-step-name', { ns: NS.EXPERIMENTS })}
          title={t('search-step-name', { ns: NS.EXPERIMENTS })}
          role={'none'}
          value={experimentPanelAgentStepSearchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(event: any, newValue: string) => setExperimentPanelAgentStepSearchValue(newValue)}
          onSearch={(newValue: string) => setExperimentPanelAgentStepSearchValue(newValue)}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          spellCheck="false"
        />
      ),
    },
    {
      key: 'status-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-status', { ns: NS.COMMON })}
          title={t('select-status', { ns: NS.COMMON })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={experimentPanelAgentStepStatusValues}
          options={stepsStatusList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event: any, option: any) => {
            if (option.selected && !experimentPanelAgentStepStatusValues.includes(option.key)) {
              setExperimentPanelAgentStepStatusValues([...experimentPanelAgentStepStatusValues, option.key as string]);
            } else if (!option.selected && experimentPanelAgentStepStatusValues.includes(option.key)) {
              setExperimentPanelAgentStepStatusValues(
                experimentPanelAgentStepStatusValues.filter((key: string) => key !== option.key),
              );
            }
          }}
        />
      ),
    },
    {
      key: 'step-type-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-step-type', { ns: NS.EXPERIMENTS })}
          title={t('select-step-type', { ns: NS.EXPERIMENTS })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={experimentPanelAgentStepTypeValues}
          options={stepsTypeList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event: any, option: any) => {
            if (option.selected && !experimentPanelAgentStepTypeValues.includes(option.key)) {
              setExperimentPanelAgentStepTypeValues([...experimentPanelAgentStepTypeValues, option.key as string]);
            } else if (!option.selected && experimentPanelAgentStepTypeValues.includes(option.key)) {
              setExperimentPanelAgentStepTypeValues(experimentPanelAgentStepTypeValues.filter((key: string) => key !== option.key));
            }
          }}
        />
      ),
    },
    {
      key: 'date-filter',
      onRender: () => (
        <DefaultButton
          text={t('end-time', { ns: NS.TABLE })}
          title={t('end-time', { ns: NS.TABLE })}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.defaultButton}
          menuProps={menuPropsAgentSteps}
        />
      ),
    },
    {
      key: 'reset',
      onRender: () => (
        <DefaultButton
          text={t('reset', { ns: NS.COMMON })}
          title={t('reset', { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.RESET }}
          onClick={handleClearAgentStepFilters}
          className={filterBarStyles['pagefilterbar-button']}
          styles={filterBar.defaultButton}
        />
      ),
    },
    {
      key: 'divider',
      onRender: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: 'edit-columns',
      onRender: () => (
        <DefaultButton
          key={'edit-columns'}
          text={t('edit-columns', { ns: NS.EXPERIMENTS })}
          title={t('edit-columns', { ns: NS.EXPERIMENTS })}
          iconProps={{ iconName: SystemIcons.EDIT_COLUMNS }}
          onClick={openSessionStepsColumnEditor}
          className={filterBarStyles['pagefilterbar-button']}
          styles={filterBar.defaultButton}
        />
      ),
    },
    {
      key: 'group-by-column',
      onRender: () => (
        <CommandBarButton
          text={t(sessionStepsGroupByValue, { ns: NS.TABLE }) || groupByNoneKey}
          title={t('group-by-column', { ns: NS.EXPERIMENTS })}
          className={filterBarStyles['pagefilterbar-button']}
          iconProps={{ iconName: SystemIcons.GROUP_LIST }}
          menuProps={{ items: [groupByNoneItem, ...groupByProps] }}
        />
      ),
    },
  ];

  const onLinkClick = (item: React.ReactElement) => {
    const key = item.props.itemKey;

    setTabMemory(TabMemoryKeys.SESSION_DETAILS_MODAL, key);
    clearSelectedSessionStep();
  };

  const onJsonLinkClick = (item: React.ReactElement) => {
    const key = item.props.itemKey;

    setTabMemory(TabMemoryKeys.SESSION_DETAILS_MODAL_JSON, key);
  };

  const onDismissMessage: onDismissType = () => {
    clearSessionPanelMessages();
  };

  const resetPanel = (row: SessionType) => {
    setSelectedSession(row);
    clearSessionPanelMessages();
    clearSelectedSessionStep();
  };

  React.useEffect(() => {
    selectedSession && resetPanel(selectedSession);
  }, [selectedSession?.id, canCancelSession]);

  const detailsHeader = (
    <div className={`${styles['auto-collapsing-header']}`}>
      <div className={`${styles['header-row']}`} title={selectedSessionTitle}>
        <div className={styles['title-text']}>
          <div className={styles['title-truncate']}>{selectedSessionTitle}</div>
        </div>
        {isSessionSelected && (
          <Link
            className={styles['title-link']}
            to={{
              pathname: selectedSessionRoutePath,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={styles['title-icon']} iconName={SystemIcons.OPEN_IN_NEW_WINDOW} />
          </Link>
        )}
      </div>
      <div className={styles['close-button']}>
        {isReadingPaneBottom && (
          <IconButton
            title={t('show-right-pane', { ns: NS.EXPERIMENTS })}
            aria-label={t('show-right-pane', { ns: NS.EXPERIMENTS })}
            iconProps={{ iconName: SystemIcons.MOVE_RIGHT }}
            onClick={setPreviewPaneRight}
          />
        )}
        {isReadingPaneRight && (
          <IconButton
            title={t('show-bottom-pane', { ns: NS.EXPERIMENTS })}
            aria-label={t('show-bottom-pane', { ns: NS.EXPERIMENTS })}
            iconProps={{ iconName: SystemIcons.MOVE_DOWN }}
            onClick={setPreviewPaneBottom}
          />
        )}

        <IconButton
          title={t('close-session', { ns: NS.EXPERIMENTS })}
          aria-label={t('close-session', { ns: NS.EXPERIMENTS })}
          iconProps={{ iconName: SystemIcons.CANCEL }}
          onClick={clearSelectedSession}
        />
      </div>
    </div>
  );

  const columnEditorUserSettings: ColumnEditorUserSettingsType = {
    headerText: t('choose-steps-columns', { ns: NS.TITLES }),
    styles: SidePanelStyles.sessionStepColumnEditorPanel,
  };

  const splitPanelConfig: SplitPanelConfigType = {
    defaultSize: [350, 400],
    minSize: [200, 350],
    maxSize: [-200, -200], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
    keys: [ReadingPaneStateKeys.SESSION_DETAILS_MAIN, ReadingPaneStateKeys.SESSION_DETAILS_PROPERTIES],
    offModeSize: ['50%', '50%'],
    padding: 300, // Padding to prevent the pane from being resized to the full height of the window.
  };

  const detailsSplitDirection: SplitPanelDirectionType =
    isOutlookMode && isReadingPaneBottom ? SplitPanelDirectionType.VERTICAL : SplitPanelDirectionType.HORIZONTAL;

  return (
    <SessionDetailsTemplate
      agentFilterItems={agentFilterItems}
      chart={chart}
      checkboxVisibility={CheckboxVisibility.hidden}
      columnEditorUserSettings={columnEditorUserSettings}
      compiledJsonStyle={compiledJsonStyle}
      content={content}
      detailsSplitDirection={detailsSplitDirection}
      farItems={farItems}
      filteredStep={filteredStep}
      generalCommandBarItems={generalCommandBarItems}
      groupByColumn={sessionStepsGroupByColumn}
      groupByColumns={stepsColumnDefinitions}
      onJsonLinkClick={onJsonLinkClick}
      onLinkClick={onLinkClick}
      pageHeader={detailsHeader}
      rawJsonStyle={rawJsonStyle}
      selection={selection}
      setContent={setContent}
      splitPanelConfig={splitPanelConfig}
      stepChanges={stepChanges}
      stepDisplayItems={stepDisplayItems}
      tableGroups={tableGroups}
    />
  );
};

const SessionDetailsViewController = observer(SessionDetailsViewControllerFC);

export default SessionDetailsViewController;
