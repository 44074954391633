import React from 'react';

import { RootStore } from '@/stores/RootStore';

class ExperimentEditorBetaViewModel {
  protected _fileHandle: FileSystemFileHandle;
  protected _fileName: string;

  constructor(rootStore: RootStore) {
    const { experimentEditorStore } = rootStore;
    const { fileHandle, fileName } = experimentEditorStore;

    this._fileHandle = fileHandle;
    this._fileName = fileName;
  }

  public get fileHandle(): FileSystemFileHandle {
    return this._fileHandle;
  }

  public get fileName(): string {
    return this._fileName;
  }
}

export default ExperimentEditorBetaViewModel;
